import React, { Suspense, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { ERROR_TRANSLATIONS, DATE_FORMAT, STREAM_SOURCES } from "shared/constants";
import useHeaderData from "hooks/shared/useHeaderData";
import GameFrame from "components/Frames/GameFrame";
import DMSFrame from "components/Frames/DMSFrame";
import Modal from "@livecasino/core/v2/components/Modal";
import getErrorObjectByType from "@livecasino/core/helpers/getErrorObjectByType";
import ErrorContent from "@livecasino/core/v2/Backoffice_DMS_Pitboss/components/ErrorContent/ErrorContent";
import DMSGameDetailsHeader from "../../DMSGameDetailsHeader";
import { momentFormatter } from "../../../shared/utils";

import styles from "./index.module.scss";
import DMSGameStreamSwitchRadioButtons from "../../DMSGameStreamSwitchRadioButtons";

const GameAndDmsWithTabs = ({
    tableId,
    getAsyncToken,
    dmsUrl,
    gameUrl,
    displayNotification,
    tabsContentComponent: TabsContentComponent,
    gameType
}) => {
    const [selectedStreamSource, setSelectedStreamSource] = useState(STREAM_SOURCES.LOCAL);

    const {
        roundId,
        startTime,
        totalBets,
        dealerNickname,
        isGameMuted,
        players,
        viewers,
        betters,
        error,
        tableName,
        streamData
    } = useHeaderData({ getAsyncToken, onReceiveInfoMessage: displayNotification, gameType });

    const errorObject = useMemo(() => {
        if (!error) {
            return null;
        }
        return getErrorObjectByType({ error, translation: ERROR_TRANSLATIONS });
    }, [error]);

    return (
        <>
            <div className={`${styles.PitBossNewWindow} ${error ? "blur-background" : ""}`}>
                <DMSGameDetailsHeader>
                    <DMSGameDetailsHeader.Item propertyName="Round ID" value={roundId} />
                    <DMSGameDetailsHeader.Item propertyName="Total Bet" value={totalBets} />
                    <DMSGameDetailsHeader.Item
                        propertyName="Start Time"
                        value={momentFormatter(startTime, DATE_FORMAT)}
                    />
                    <DMSGameDetailsHeader.Item propertyName="Dealer NickName" value={dealerNickname} />
                    <div className={styles.StreamSwitchRadioButtons}>
                        <DMSGameStreamSwitchRadioButtons
                            selectedSource={selectedStreamSource}
                            onSourceChange={setSelectedStreamSource}
                        />
                    </div>
                </DMSGameDetailsHeader>
                <div className={styles.IFramesContainer}>
                    <GameFrame
                        selectedStreamSource={selectedStreamSource}
                        streamData={streamData}
                        iframeUrl={gameUrl}
                        activePlayersCount={players}
                        viewers={viewers}
                        betters={betters}
                        isGameMuted={isGameMuted}
                    />
                    <DMSFrame iframeURL={dmsUrl} />
                </div>
                <div className={styles.TabsWrapper}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <TabsContentComponent
                            tableId={tableId}
                            getAsyncToken={getAsyncToken}
                            roundId={roundId}
                            tableName={tableName}
                        />
                    </Suspense>
                </div>
            </div>
            <Modal open={Boolean(error)} backdropFilterValue="4px" backdropBackgroundColor="transparent">
                <div className={styles.ErrorContentWrapper}>
                    <ErrorContent title={errorObject?.title} texts={errorObject?.text} />
                </div>
            </Modal>
        </>
    );
};

GameAndDmsWithTabs.propTypes = {
    tableId: PropTypes.string.isRequired,
    getAsyncToken: PropTypes.func.isRequired,
    dmsUrl: PropTypes.string.isRequired,
    gameUrl: PropTypes.string.isRequired,
    displayNotification: PropTypes.func.isRequired,
    tabsContentComponent: PropTypes.object.isRequired,
    gameType: PropTypes.string.isRequired
};

export default GameAndDmsWithTabs;
